import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import Box from '@mui/material/Box';
function MyVerticallyCenteredModal(props) {
    const [message, setMessage] = useState(''); // For success or error messages
    const [formData, setFormData] = useState({
        fingerprint: '',
        userName: '',
        company: '',
        cr_key: '',
      });
    

      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };

  const close = async(e) => {
    setFormData({
      fingerprint: '',
      userName: '',
      company: '',
      cr_key: '',
    });
    setMessage('');
    props.onHide();

  }

      
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const apiURL = "https://dd7lltrl2i.execute-api.us-east-1.amazonaws.com/prod/activation";
  
    try {
      const response = await fetch(apiURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': 'V3B0ZCYpZP8TIdOhPi7Jq8huEzpFvQI4194Pu35A',
        },
        body: JSON.stringify({
          cr_Key: formData.cr_key,
          fingerprint: formData.fingerprint,
          name: formData.userName,
          company: formData.company,
        }),
      });
  
      if (!response.ok) {
        // If server response is not OK, throw an error
        throw new Error(`Server responded with a status of ${response.status}`);
      }
  
      const data = await response.json(); // Make sure to await the parsing of the JSON
  
      // Assuming the API correctly returns a JSON object with an "activationKey" field
      if (data.activationKey) 
      {
        setMessage(`Activation Key: ${data.activationKey}`);
      } 
      else 
      {
        // Handle the case where "activationKey" is not in the response for some reason
        setMessage('The given input is incorrect or failed to fetch the activation key.');
      }
    } catch (error) {
      console.error('There was an error!', error);
      setMessage('The given input is incorrect or an error occurred.');
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='my-custom-modal'
      onHide={close}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Software Activation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >
      <form onSubmit={handleSubmit}>
      <Box className = '' sx={{ display: 'flex' , alignItems: 'center' ,  flexDirection: 'column' , justifyContent: 'center' , flexWrap: 'wrap' , alignContent : 'center'}}>
      <input
          type="text"
          name="fingerprint"
          value={formData.fingerprint}
          onChange={handleChange}
          placeholder="Fingerprint"
          required // Makes this field required
        />
        <input
          type="text"
          name="userName"
          value={formData.userName}
          onChange={handleChange}
          placeholder="User Name"
          required // Makes this field required
        />
        <input
          type="text"
          name="company"
          value={formData.company}
          onChange={handleChange}
          placeholder="Company"
          required // Makes this field required
        />
        <input
          type="text"
          name="cr_key"
          value={formData.cr_key}
          onChange={handleChange}
          placeholder="Customer Registration Key"
          required // Makes this field required

        />
        <button type="submit">Activate</button>
        </Box>
        
      {message && <p>{message}</p>} {/* Display the message */}
      </form>
      </Modal.Body>
      <Modal.Footer >
        <Button onClick={close}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MyVerticallyCenteredModal;