import React from 'react';
import MyVerticallyCenteredModal from './modal';
function ActivationForm() {

  const [modalShow, setModalShow] = React.useState(false);
 
  const showmodal = (e) =>
  {
    e.preventDefault();
    setModalShow(true);
  }



  return (
<body className='App-background' style={{
  backgroundImage: `url(/background.jpg)`,
  backgroundRepeat: "no-repeat"
}}>

      <div className="App">
    <header className="App-header">
        {/* Navigation and logo here */}
      </header>
      <main className="Main-content">
      <a  sx={{}} href="/" aria-current="page" class="nav-logo-link w-inline-block w--current" >  <img className ='logo'  src="https://assets-global.website-files.com/5e4577b3b17c575691e58a3e/5e4c5cb0fa3b30ee026ff88a_EXI_LogoRGB-WhiteBlue_6Feb2020.png" sizes="(max-width: 767px) 100vw, 150px" srcset="https://assets-global.website-files.com/5e4577b3b17c575691e58a3e/5e4c5cb0fa3b30ee026ff88a_EXI_LogoRGB-WhiteBlue_6Feb2020-p-500.png 500w, https://assets-global.website-files.com/5e4577b3b17c575691e58a3e/5e4c5cb0fa3b30ee026ff88a_EXI_LogoRGB-WhiteBlue_6Feb2020-p-800.png 800w, https://assets-global.website-files.com/5e4577b3b17c575691e58a3e/5e4c5cb0fa3b30ee026ff88a_EXI_LogoRGB-WhiteBlue_6Feb2020-p-1080.png 1080w, https://assets-global.website-files.com/5e4577b3b17c575691e58a3e/5e4c5cb0fa3b30ee026ff88a_EXI_LogoRGB-WhiteBlue_6Feb2020.png 2000w" alt=""  /> 
      
      
      <button className="demo-button" onClick={(e) => ( showmodal(e)) } >Software Activation</button> </a>

      <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
      />


        <section className="Hero">
          <h4>Thank you for purchasing the MassBox!</h4>
          <p className="content-text">
            THE MASSBOX™
            <br />
            Enabling materials scientists and engineers to spend less time acquiring data and more
            time learning from it, Exum™ builds instruments and software ecosystems that combine
            high performance with ease-of-use.
          </p>

        </section>
        
        <aside className="Product-image">
          {/* Product image and details here */}
        </aside>

   
      </main>
      <footer className="App-footer">
        {/* Footer content here, including cookie policy */}
      </footer>
    </div>
      
    </body>
  );
}

export default ActivationForm;
