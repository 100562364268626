import React from 'react';
import ActivationForm from './ActivationForm'; // Adjust the path as necessary

function App() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <ActivationForm />
      <footer>
      </footer>
    </div>
  );
}

export default App;
